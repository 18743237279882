<template>
  <v-dialog v-model="open" width="900" :fullscreen="mobile">
    <v-card :rounded="mobile ? 0 : 'lg'">
      <div class="highlight flex-align py-2 px-4">
        <div class="text-3.5 font-bold">Keyboard Shortcuts</div>
        <v-spacer />
        <v-btn @click="close" size="35" variant="text" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </div>
      <v-divider />
      <scroll-content vertical class="pa-4 md:max-h-500px">
        <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div v-for="(shortcut, i) in shortcuts" :key="i" class="lt-md:mt-4">
            <div class="text-3.5 font-bold">{{ shortcut.name }}</div>
            <table class="table-auto w-full border-collapse">
              <tbody>
                <tr
                  v-for="(item, j) in shortcut.items"
                  :key="`${i}-${j}`"
                  class="even:bg-gray/20"
                >
                  <td class="py-1 text-3.2">
                    {{ item.description }}
                  </td>
                  <td class="py-1">
                    <div class="flex justify-end gap-2">
                      <div
                        class="text-3 bg-gray/20 rounded-md px-1.7 h-20px flex-align justify-center"
                        v-for="key in keys(item.keys)"
                        :key="key"
                      >
                        {{ key }}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </scroll-content>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useDisplay } from "vuetify";

export default defineComponent({
  name: "KeyboardShortcutsHelp",
  setup() {
    const open = ref(false);
    const { mobile } = useDisplay();
    const { listen } = useAppEvent();
    const config = useAppConfig();

    listen("app:help", () => {
      open.value = true;
    });

    return {
      open,
      mobile,
      shortcuts: config.app.shortcuts,
    };
  },
  methods: {
    close() {
      this.open = false;
    },
    keys(keys: string | string[]) {
      return Array.isArray(keys) ? keys : [keys];
    },
  },
});
</script>
