<template>
  <div
    class="scroll-content"
    :class="{
      vertical,
      height: !!height,
      width: !!width,
    }"
    :style="style"
  >
    <slot></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "ScrollContent",
  props: {
    vertical: { type: Boolean, default: false },
    height: { type: [String, Number] },
    width: { type: [String, Number] },
    shadow: {
      type: String as PropType<
        "top" | "bottom" | "left" | "right" | "vertical" | "horizontal"
      >,
    },
  },
  computed: {
    style() {
      const s: any = {};

      if (this.vertical && this.height) {
        s["--max-height"] = convertToUnit(this.height);
      } else if (!this.vertical && this.width) {
        s["--max-width"] = convertToUnit(this.width);
      }

      const shadowX = !this.shadow && !this.vertical;
      const shadowY = !this.shadow && this.vertical;
      const shadowLeft = shadowX || this.shadow == "left";
      const shadowRight = shadowX || this.shadow == "right";
      const shadowTop = shadowY || this.shadow == "top";
      const shadowBottom = shadowY || this.shadow == "bottom";

      const shadow: string[] = [];

      // s["--background"] = shadow.join(",");

      return s;
    },
  },
});
</script>

<style lang="scss" scoped>
.scroll-content {
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  &.width:not(.vertical) {
    max-width: var(--max-width);
  }
  &.vertical.height {
    max-height: var(--max-height);
  }
}
</style>
