<template>
  <ActionDialog
    v-for="entry in entries"
    :key="entry.id"
    v-bind="entry.options"
    :done-action="entry.confirm"
    :cancel-action="entry.cancel"
    eager
    persistent
    pad
  />
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useConfirmStore } from "../private/confirm";

export default defineComponent({
  name: "ConfirmDialog",
  setup() {
    const { entries } = useConfirmStore();

    return {
      entries,
    };
  },
});
</script>
